import { useEffect, useState } from "react";
import { IUser, Investment } from "../types";
import { Container, Card, Table } from "react-bootstrap";
import axios from "axios";

interface Props {
  user: IUser;
}

const UserInfo: React.FC<Props> = ({ user }) => {
  const [investments, setInvestments] = useState<Investment[]>([]);

  useEffect(() => {
    const fetchInvestments = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/investments/${user._id}`
      );
      setInvestments(response.data);
    };
    fetchInvestments();
  }, [user]);

  return (
    <Container style={{ maxWidth: "600px", marginTop: "20px" }}>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title style={{ color: "#007bff" }}>
            Welcome, {user.first_name} {user.last_name}
          </Card.Title>
          <Card.Text>
            <strong>Email:</strong> {user.email}
          </Card.Text>
          <Card.Text>
            <strong>Wallet Address:</strong>{" "}
            <span style={{ color: "#28a745" }}>{user.wallet_address}</span>
          </Card.Text>
        </Card.Body>
      </Card>

      {investments.length > 0 && (
        <Card>
          <Card.Header>
            <strong>Your Investments</strong>
          </Card.Header>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Initial Investment</th>
                <th>Total Return</th>

                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {investments.map((investment) => (
                <tr key={investment._id}>
                  <td>{new Date(investment.createdAt).toLocaleDateString()}</td>
                  <td>${investment.initial_investment}</td>
                  <td>${investment.total_return}</td>
                  <td>{investment.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </Container>
  );
};

export default UserInfo;
