import InvestmentForm from "../components/InvestmentForm";
import TaxForm from "../components/TaxForm";
import SignUpPage from "./SignUpPage";

export default function AdminPage() {
  return (
    <div style={{ marginLeft: "30%", padding: "50px 0" }}>
      <h1>Admin page</h1>

      <SignUpPage />

      <InvestmentForm onSubmit={() => console.log("Investment created")} />
    </div>
  );
}
