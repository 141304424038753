import React, { useState } from "react";
import axios from "axios";
import LeftSidebar from "../components/LeftSidebar";
const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState(""); // State for role input
  const [error, setError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const handleSignUp = async () => {
    // Password complexity validation
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and contain at least one uppercase letter and one digit."
      );
      return;
    } else {
      setPasswordError(null);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
        {
          email,
          password,
          wallet_address: walletAddress,
          first_name: firstName,
          last_name: lastName,
          gender,
          role, // Include role in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      // Handle successful sign-up
      console.log(response.data);
      // Clear the form fields
      setEmail("");
      setPassword("");
      setWalletAddress("");
      setFirstName("");
      setLastName("");
      setGender("")
      setRole(""); // Clear role input
      // Set success message
      setError("User registered");
    } catch (error) {
      console.error("Error signing up:", error);
      setError("An error occurred during sign-up. Please try again.");
    }
  };

  return (
    <>
      <LeftSidebar />
      <div className="container">
        <div className="formContainer">
          <h2 className="title">Sign Up</h2>
          {error && <p className="error">{error}</p>}
          <form className="form">
            <div className="inputGroup">
              <label className="label" htmlFor="email">
                Email
              </label>
              <input
                className="input"
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="inputGroup">
              <label className="label" htmlFor="password">
                Password
              </label>
              <input
                className={`input ${passwordError ? "errorInput" : ""}`}
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && <p className="error">{passwordError}</p>}
            </div>
            <div className="inputGroup">
              <label className="label" htmlFor="walletAddress">
                Wallet Address
              </label>
              <input
                className="input"
                id="walletAddress"
                type="text"
                placeholder="Wallet Address"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
              />
            </div>
            <div className="inputGroup">
              <label className="label" htmlFor="firstName">
                First Name
              </label>
              <input
                className="input"
                id="firstName"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="inputGroup">
              <label className="label" htmlFor="lastName">
                Last Name
              </label>
              <input
                className="input"
                id="lastName"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="inputGroup">
              <label className="label" htmlFor="gender">
                Gender
              </label>
              <select
                className="input"
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="inputGroup">
              <label className="label" htmlFor="role">
                Role
              </label>
              <select
                className="input"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Select Role</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </div>
            <button className="button" type="button" onClick={handleSignUp}>
              Sign Up
            </button>
          </form>
        </div>
      </div>
      <div className="formContainer"></div>
    </>
  );
};

export default SignUpPage;
