import { useContext, useState } from "react";
import axios, { AxiosError } from "axios";
import "../login.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../middleware/AuthContext";
import { AuthContextType } from "../types";

interface ErrorResponse {
  message: string;
}

const LoginPage: React.FC = () => {
  const { setUser } = useContext(AuthContext) as AuthContextType;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);

  const handleLogin = async () => {
    setEmailError(null);
    setPasswordError(null);
    setError(null);

    // Front-end validation
    if (!email) {
      setEmailError("Email is required");
    }
    if (!password) {
      setPasswordError("Password is required");
    }

    if (!email || !password) {
      return; // Stop further execution if any field is empty
    }

    console.log(`${process.env.REACT_APP_BACKEND_URL}/auth/login`)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email,
          password,
        }
      );

      // Check user role
      const role = response.data.data.role;
      console.log(role)
      localStorage.setItem("user", JSON.stringify({ email, role }));
      console.log(response.data)
      // localStorage.setItem("token", JSON.stringify(response.data.data.token));
      // Redirect based on user role
      setUser(response.data.data);
      if (role === "admin") {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<ErrorResponse>;
        if (axiosError.response?.status === 401) {
          setError("Invalid username or password");
        } else {
          setError("An unknown error occurred during login.");
        }
      } else {
        console.error(error)

        setError("A network error occurred. Please try again later.");
      }
    } finally {
      if (rememberMe) {
        localStorage.setItem("rememberMe", String(rememberMe));
        localStorage.setItem("email", email);
      } else {
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("email");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="formContainer">
        <h2 className="title">Login</h2>
        {error && <p className="error">{error}</p>}
        <form className="form">
          <div className="inputGroup">
            <label className="label" htmlFor="email">
              Email
            </label>
            <input
              className={`input ${emailError ? "errorInput" : ""}`}
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="error">{emailError}</p>}
          </div>
          <div className="inputGroup">
            <label className="label" htmlFor="password">
              Password
            </label>
            <input
              className={`input ${passwordError ? "errorInput" : ""}`}
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <p className="error">{passwordError}</p>}

            <div className="inputGroup">
              <input
                id="rememberMe"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="rememberMe" className="labelCheckbox">
                Remember Me
              </label>
            </div>
          </div>
          <button className="button" type="button" onClick={handleLogin}>
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
