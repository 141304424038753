import React, { useContext } from "react";
import { AuthContext } from "../middleware/AuthContext";
import { AuthContextType } from "../types";
import UserInfo from "../components/UserInfo";

const UserDashboardPage = () => {
  const { user } = useContext(AuthContext) as AuthContextType;
  console.log(user);

  if (!user) return <></>;
  return (
    <div>
      <UserInfo user={user} />
    </div>
  );
};

export default UserDashboardPage;