import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { AuthContextType } from "../types";

interface props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<props> = ({ children }) => {
  const { user } = useContext(AuthContext) as AuthContextType;

  if (!user) return <Navigate to="/login" />;

  return <>{children}</>;
};

export default RequireAuth;
