import React, { useState } from "react";
import axios from "axios";

interface InvestmentFormProps {
  onSubmit: () => void;
}

const InvestmentForm: React.FC<InvestmentFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [initialInvestment, setInitialInvestment] = useState("");
  const [totalReturn, setTotalReturn] = useState("");
  const [imoDepositAmount, setImoDepositAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/investments/create`,
        {
          email,
          initial_investment: initialInvestment
            ? parseFloat(initialInvestment)
            : 0, // Ensures conversion to number with fallback
          total_return: totalReturn ? parseFloat(totalReturn) : 0, // Ensures conversion to number with fallback
          imo_deposit_amount: imoDepositAmount
            ? parseFloat(imoDepositAmount)
            : 0, // Ensures conversion to number with fallback,
          tax_amount: taxAmount,
        }
      );
      console.log(response.data);
      // Reset form fields after successful submission
      setEmail("");
      setInitialInvestment("");
      setTotalReturn("");
      setImoDepositAmount("");
      setTaxAmount("");
      // Callback function to indicate successful submission
      onSubmit();
    } catch (error) {
      setError(
        "An error occurred while creating the investment. Please try again."
      );
    }
  };

  return (
    <div>
      <div>
        <h2>Create Investment</h2>
        {error && <p>{error}</p>}
        <div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="initialInvestment">Initial Investment</label>
            <input
              id="initialInvestment"
              type="number"
              placeholder="Initial Investment"
              value={initialInvestment}
              onChange={(e) => setInitialInvestment(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="totalReturn">Total Return</label>
            <input
              id="totalReturn"
              type="number"
              placeholder="Total Return"
              value={totalReturn}
              onChange={(e) => setTotalReturn(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="imoDepositAmount">IMO Deposit Amount</label>
            <input
              id="imoDepositAmount"
              type="number"
              placeholder="IMO Deposit Amount"
              value={imoDepositAmount}
              onChange={(e) => setImoDepositAmount(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="taxAmount">Tax Amount</label>
            <input
              id="taxAmount"
              type="number"
              placeholder="Tax Amount"
              value={taxAmount}
              onChange={(e) => setTaxAmount(e.target.value)}
            />
          </div>
          <button type="button" onClick={handleSubmit}>
            Create Investment
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentForm;
