import React, { useState } from "react";
import axios from "axios";
import { Investment, EditValues } from "../types"; // Adjust the import path as needed

interface InvestmentsTableProps {
  investments: Investment[];
  handleUserClick: (userId: string) => void;
  setInvestments: React.Dispatch<React.SetStateAction<Investment[]>>;
}

const InvestmentsTable: React.FC<InvestmentsTableProps> = ({
  investments,
  handleUserClick,
  setInvestments,
}) => {
  const [editValues, setEditValues] = useState<EditValues>({});

  const handleInputChange = (
    id: string,
    field: keyof Omit<Investment, "_id" | "user_id">,
    value: string
  ) => {
    setEditValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleCheckClick = async (
    id: string,
    field: keyof Omit<Investment, "_id" | "user_id">
  ) => {
    const investmentToUpdate = editValues[id];
    if (!investmentToUpdate || investmentToUpdate[field] === undefined) return;

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/investments/update/${id}`,
        {
          [field]: investmentToUpdate[field],
        }
      );
      alert("Investment updated successfully");
      // Update the local state with the new investment details
      setInvestments((prevInvestments) =>
        prevInvestments.map((inv) =>
          inv._id === id ? { ...inv, [field]: investmentToUpdate[field] } : inv
        )
      );
      // Reset edit values for the updated investment
      // Reset edit values for the updated investment to its initial state or remove it
      setEditValues((prev) => {
        const newState = { ...prev };
        delete newState[id]; // This removes the entry entirely, avoiding setting it to undefined
        return newState;
      });
    } catch (error) {
      console.error("Failed to update investment", error);
      alert("Failed to update investment");
    }
  };

  return (
    <div>
      <h2>Investments Table</h2>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Initial Investment</th>
            <th>Total Return</th>
            <th>IMO Deposit Amount</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Tax Amount</th>
          </tr>
        </thead>
        <tbody>
          {investments.map((investment) => (
            <tr key={investment._id}>
              <td
                onClick={() => handleUserClick(investment.user_id._id)}
              >{`${investment.user_id.first_name} ${investment.user_id.last_name}`}</td>
              {(
                [
                  "initial_investment",
                  "total_return",
                  "imo_deposit_amount",
                  "createdAt",
                  "status",
                  "tax_amount",
                ] as const
              ).map((field) => {
                const fieldValue = investment[field]?.toString() || "";
                return (
                  <td key={field}>
                    {editValues[investment._id] &&
                    editValues[investment._id][field] !== undefined ? (
                      <div>
                        <input
                          type={field === "createdAt" ? "date" : "text"}
                          value={editValues[investment._id][field] || ""}
                          onChange={(e) =>
                            handleInputChange(
                              investment._id,
                              field,
                              e.target.value
                            )
                          }
                        />
                        <button
                          onClick={() =>
                            handleCheckClick(investment._id, field)
                          }
                        >
                          ✓
                        </button>
                      </div>
                    ) : (
                      <span
                        onClick={() =>
                          handleInputChange(investment._id, field, fieldValue)
                        }
                      >
                        {field === "createdAt"
                          ? new Date(investment.createdAt).toLocaleDateString()
                          : fieldValue}
                      </span>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvestmentsTable;
