import { useEffect, useState } from "react";
import { User } from "../types";
import axios from "axios";
import { Button } from "react-bootstrap";

const UserDetailsTable = ({ user }: { user: User | null }) => {
  return (
    <div className="py-5">
      <h2>User Details Table</h2>
      {user ? (
        <>
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Wallet Address</th>
                <th>Role</th>
                <th>Initial Investment</th>
                <th>Total Return</th>
                <th>IMO Deposit Amount</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Tax Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.wallet_address}</td>
                <td>{user.role}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {user.investments?.map((investment) => (
                <tr key={investment._id}>
                  <td colSpan={5}></td>
                  <td>{investment.initial_investment}</td>
                  <td>{investment.total_return}</td>
                  <td>{investment.imo_deposit_amount}</td>
                  <td>{new Date(investment.createdAt).toLocaleString()}</td>
                  <td>{investment.status}</td>
                  <td>{investment.tax_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <p>No user selected</p>
      )}
    </div>
  );
};

export default UserDetailsTable;
