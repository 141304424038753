import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext } from "./middleware/AuthContext"; // Adjust the path as necessary
import AppRouter from "../src/components/AppRouter";
import "bootstrap/dist/css/bootstrap.min.css";

const App: React.FC = () => {
  const storedUser = localStorage.getItem("user");
  let parsedUser = null;
  if (storedUser) {
    parsedUser = JSON.parse(storedUser); // Parse the user string back into an object
  }

  const [user, setUser] = useState(parsedUser);

  return (
    <Router>
      <AuthContext.Provider value={{ user, setUser }}>
        <AppRouter />
      </AuthContext.Provider>
    </Router>
  );
};

export default App;
