import React from 'react';
import { Link } from 'react-router-dom';

function LeftSidebar() {
  return (
    <aside className="left-sidebar">
      <nav>
        <ul className="sidebar-nav">
          <li>
            <Link to="/admin/investments" className="sidebar-link">
              Investments
            </Link>
          </li>
          <li>
            <Link to="/admin/users" className="sidebar-link">
              Users
            </Link>
          </li>
          <li>
            <Link to="/admin" className="sidebar-link">
              Home
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default LeftSidebar;
