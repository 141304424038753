import React, { useEffect, useState } from "react";
import InvestmentsTable from "../components/InvestmentsTable";
import UserDetailsTable from "../components/UserDetailsTable";
import { Investment, User } from "../types";

const InvestmentsPage = () => {
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/investments`
        );
        if (response.ok) {
          const data = await response.json();
          setInvestments(data);
        } else {
          setError("Failed to fetch investments: " + response.statusText);
        }
      } catch (error) {
        setError(`Error fetching investments: ${error}`);
      }
    };
    fetchInvestments();
    setInterval(fetchInvestments, 10000);
  }, []);

  const handleUserClick = async (userId: string) => {
    try {
      // Fetch user details
      const userResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`
      );
      if (!userResponse.ok) {
        throw new Error(
          `Failed to fetch user details: ${userResponse.statusText}`
        );
      }
      const userData = await userResponse.json();

      // Fetch investments related to the user
      const investmentResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/investments/${userId}`
      );
      if (!investmentResponse.ok) {
        throw new Error(
          `Failed to fetch investments: ${investmentResponse.statusText}`
        );
      }
      const investmentData = await investmentResponse.json();

      // Merge user details with associated investments
      const userWithInvestments: User = {
        ...userData,
        investments: investmentData,
      };

      setSelectedUser(userWithInvestments);
      setError("");
    } catch (error) {
      const errorMessage = `Error fetching investment details: ${error}`;
      setError(errorMessage); // Set error as string
    }
  };

  return (
    <div style={{ marginLeft: "30%" }}>
      <h1>Investments</h1>
      {error && <p>{error}</p>}
      <InvestmentsTable
        setInvestments={setInvestments}
        investments={investments}
        handleUserClick={handleUserClick}
      />
      <UserDetailsTable user={selectedUser} />
    </div>
  );
};

export default InvestmentsPage;
