import { useEffect, useState } from "react";
import { User } from "../types";

export default function UsersPage() {
    const [userData, setUserData] = useState<User[]>([]); // Updated to explicitly define the type

    const fetchUserData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(()=>{
        fetchUserData()
    }, [])

  return (
      <table className="users-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Wallet Address</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((user) => (
            <tr key={user._id}>
              <td>{user._id}</td>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>{user.wallet_address}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
  )
}
