import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import RequireAuth from "../middleware/RequireAuth"; // Adjust the import path
import LoginPage from "../pages/LoginPage";
import InvestmentsPage from "../pages/InvestmentsPage";
import UsersPage from "../pages/UsersPage";
import AdminPage from "../pages/AdminPage";
import LeftSidebar from "./LeftSidebar";
import RequireAdmin from "../middleware/RequireAdmin";
import UserDashboardPage from "../pages/UserDashboardPage";

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <UserDashboardPage />
          </RequireAuth>
        }
      />
      {/* Redirect to /login when accessing the root path */}
      <Route
        path="/"
        element={<Navigate to="/login" />}
      />

      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/admin"
        element={
          <RequireAdmin>
            <div style={{ display: "flex" }}>
              <LeftSidebar />
              <div style={{ flexGrow: 1 }}>
                <Outlet />
              </div>
            </div>
          </RequireAdmin>
        }
      >
         <Route index element={<AdminPage />} />
        <Route
          path="investments"
          element={
            <RequireAdmin>
              <InvestmentsPage />
            </RequireAdmin>
          }
        />
        <Route
          path="users"
          element={
            <RequireAdmin>
              <UsersPage />
            </RequireAdmin>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
